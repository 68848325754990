import { B2BContactFormCosmicMetadata } from '@finn/ui-cosmic';
import { cn, parseToHtml } from '@finn/ui-utils';
import { ElementType } from 'react';

import { FormWrapper } from './FormWrapper';

// TODO share this between dynamic components
export const titleHeading = {
  h1: 'mobile-t1-semibold md:web-t1-semibold',
  h2: 'mobile-t2-semibold md:web-t2-semibold',
  h3: 'mobile-t3-semibold md:web-t3-semibold',
  h4: 'mobile-t4-semibold md:web-t4-semibold',
  h5: 'global-t5-semibold',
  h6: 'global-t6-semibold',
};

export const B2BContactForm = ({
  data,
}: {
  data: B2BContactFormCosmicMetadata;
}) => {
  const titleClassName = titleHeading[data.header_size?.key] || titleHeading.h2;
  const TitleEl = (data.header_size?.key || 'h2') as ElementType;

  return (
    <div className="container">
      <div
        id="b2b-contact-form"
        className="grid grid-cols-1 gap-6 md:grid-cols-2"
      >
        <div>
          <TitleEl className={cn(titleClassName, 'mb-14')}>
            {parseToHtml(data.title)}
          </TitleEl>
          <div className="body-16-light">{parseToHtml(data.description)}</div>
        </div>
        <FormWrapper cosmicData={data.form_type} />
      </div>
    </div>
  );
};

export default B2BContactForm;
